import { Col, Row } from 'reactstrap';
import PageHeader from './common/page-header';
import rentalPic1 from '../../assets/rentals/Rental1.jpg';
import rentalPic2 from '../../assets/rentals/Rental2.jpg';
import rentalPic3 from '../../assets/rentals/Rental3.jpg';
import rentalPic4 from '../../assets/rentals/Rental4.jpg';
import rentalPic5 from '../../assets/rentals/Rental5.jpg';
import rentalPic6 from '../../assets/rentals/Rental6.jpg';
import rentalPic7 from '../../assets/rentals/Rental7.jpg';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

const Thumbnail = styled.div`
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
`;

const ThumbnailImg = styled.img`
  margin-bottom: 10px;
  border-radius: 16px;
  box-shadow: 5px 5px 20px #555555;
`;

const ProjectImagesContainer = styled(Row)`
  margin-top: 10px;
`;

const RentalTruckContainer = styled(Element)`
  padding: 60px 50px;
`;

function RentalTrucks(props) {
  return (
    <RentalTruckContainer id="rafna307r" name="rafna307r" style={{ backgroundColor: props.backgroundColor }}>
      <Row>
        <Col>
          <Row>
            <Col>
              <PageHeader style={{ textAlign: 'center' }}>Truck Rentals</PageHeader>
            </Col>
          </Row>
          <ProjectImagesContainer className="text-center">
            <Col lg={{ size: 3 }}>
              <Thumbnail>
                <ThumbnailImg src={rentalPic1} />
              </Thumbnail>
            </Col>
            <Col lg={{ size: 3 }}>
              <Thumbnail>
                <ThumbnailImg src={rentalPic2} />
              </Thumbnail>
            </Col>
            <Col lg={{ size: 3 }}>
              <Thumbnail>
                <ThumbnailImg src={rentalPic3} />
              </Thumbnail>
            </Col>
            <Col lg={{ size: 3 }}>
              <Thumbnail>
                <ThumbnailImg src={rentalPic4} />
              </Thumbnail>
            </Col>
          </ProjectImagesContainer>
          <ProjectImagesContainer className="text-center">
            <Col md={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={rentalPic5} />
              </Thumbnail>
            </Col>
            <Col md={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={rentalPic6} />
              </Thumbnail>
            </Col>
            <Col md={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={rentalPic7} />
              </Thumbnail>
            </Col>
          </ProjectImagesContainer>
        </Col>
      </Row>
    </RentalTruckContainer>
  );
}

export default RentalTrucks;
