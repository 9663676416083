import styled from 'styled-components';
import { Button } from 'reactstrap';

const ContactUsButton = styled(Button)`
  margin-top: 1rem;
  width: 50%;
  border-radius: 1.5rem;
  height: 3rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  background-color: rgb(220, 101, 86);
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  justify-content: center;

  &:hover {
    background-color: rgb(220, 101, 86);
    color: white;
  }

  &:active {
    color: white !important;
    background-color: rgb(220, 101, 86) !important;
  }
`;

export default ContactUsButton;
