import { Col, Row } from 'reactstrap';
import PageHeader from './common/page-header';
import installPic1 from '../../assets/Rafna 307R Install - Pic 1.jpg';
import installPic2 from '../../assets/Rafna 307R Install - Pic 2.jpg';
import installPic3 from '../../assets/Rafna 307R Install - Pic 3.jpg';
import installPic4 from '../../assets/Rafna 307R Install - Pic 4.jpg';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

const ProjectImagesContainer = styled(Row)`
  margin-top: 10px;
`;

const Thumbnail = styled.div`
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
`;

const ThumbnailImg = styled.img`
  margin-bottom: 10px;
  border-radius: 16px;
  box-shadow: 5px 5px 20px #555555;
`;

const Rafna307RContainer = styled(Element)`
  padding: 60px 50px;
`;

function Rafna307R(props) {
  return (
    <Rafna307RContainer id="rafna307r" name="rafna307r" style={{ backgroundColor: props.backgroundColor }}>
      <Row>
        <Col>
          <Row>
            <Col>
              <PageHeader style={{ textAlign: 'center' }}>Rafna 307R Build</PageHeader>
            </Col>
          </Row>
          <ProjectImagesContainer className="text-center">
            <Col sm={{ size: 3 }}>
              <Thumbnail>
                <ThumbnailImg src={installPic1} style={{ marginTop: '25px', marginBottom: '35px' }} />
                <p>Rafna 307R Install - Pic 1</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 3 }}>
              <Thumbnail>
                <ThumbnailImg src={installPic2} style={{ marginTop: '25px', marginBottom: '35px' }} />
                <p>Rafna 307R Install - Pic 2</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 3 }}>
              <Thumbnail>
                <ThumbnailImg src={installPic3} />
                <p>Rafna 307R Install - Pic 3</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 3 }}>
              <Thumbnail>
                <ThumbnailImg src={installPic4} style={{ marginTop: '25px', marginBottom: '35px' }} />
                <p>Rafna 307R Install - Pic 4</p>
              </Thumbnail>
            </Col>
          </ProjectImagesContainer>
        </Col>
      </Row>
    </Rafna307RContainer>
  );
}

export default Rafna307R;
