import styled from 'styled-components';

const PageHeader = styled.div`
  /* background-color: rgb(220, 101, 86); */
  font-size: 1.5rem;
  color: black;
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 10px;
`;

export default PageHeader;
