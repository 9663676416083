import { Col, Row } from 'reactstrap';
import PageHeader from './common/page-header';
import installPic1 from '../../assets/service-lounge-1.jpg';
import installPic2 from '../../assets/service-lounge-2.jpg';
import installPic3 from '../../assets/service-lounge-3.jpg';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

const ProjectImagesContainer = styled(Row)`
  margin-top: 10px;
`;

const Thumbnail = styled.div`
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
`;

const ThumbnailImg = styled.img`
  margin-bottom: 10px;
  border-radius: 16px;
  box-shadow: 5px 5px 20px #555555;
`;

const ServiceLoungeContainer = styled(Element)`
  padding: 60px 50px;
`;

function ServiceLounge(props) {
  return (
    <ServiceLoungeContainer id="ServiceLounge" name="ServiceLounge" style={{ backgroundColor: props.backgroundColor }}>
      <Row>
        <Col>
          <Row>
            <Col>
              <PageHeader style={{ textAlign: 'center' }}>Service Lounge</PageHeader>
            </Col>
          </Row>
          <ProjectImagesContainer className="text-center">
            <Col sm={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={installPic1} />
                <p>Service Desk</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={installPic2} />
                <p>Seating Area</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={installPic3} />
                <p>Service Desk & TV</p>
              </Thumbnail>
            </Col>
          </ProjectImagesContainer>
        </Col>
      </Row>
    </ServiceLoungeContainer>
  );
}

export default ServiceLounge;
