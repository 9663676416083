import { Col, Row } from 'reactstrap';
import project1 from '../../assets/project1.jpg';
import project2 from '../../assets/project2.jpg';
import project3 from '../../assets/project3.jpg';
import project4 from '../../assets/project4.jpg';
import project5 from '../../assets/project5.jpg';
import project6 from '../../assets/project6.jpg';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

const ProjectImagesContainer = styled(Row)`
  margin-top: 10px;
`;

const Thumbnail = styled.div`
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
`;

const ThumbnailImg = styled.img`
  margin-bottom: 10px;
  border-radius: 16px;
  box-shadow: 5px 5px 20px #555555;
`;

const PastProjectsContainer = styled(Element)`
  padding: 60px 50px;
`;

function PastProjects(props) {
  return (
    <PastProjectsContainer id="pastprojects" name="pastProjects" style={{ backgroundColor: props.backgroundColor }}>
      <Row>
        <Col>
          <ProjectImagesContainer className="text-center">
            <Col sm={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={project1} />
                <p>Hi-Rail Stringline unit</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={project2} />
                <p>Hi-Rail Stringline unit</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={project3} />
                <p>Fluid Maintenance</p>
              </Thumbnail>
            </Col>
          </ProjectImagesContainer>
          <ProjectImagesContainer className="text-center">
            <Col sm={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={project4} />
                <p>Brake and Suspension Inspection</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={project5} />
                <p>Brake and Suspension Inspection</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 4 }}>
              <Thumbnail>
                <ThumbnailImg src={project6} />
                <p>New Hi-Rail Kits for install</p>
              </Thumbnail>
            </Col>
          </ProjectImagesContainer>
        </Col>
      </Row>
    </PastProjectsContainer>
  );
}

export default PastProjects;
