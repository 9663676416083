import { Col, Row } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import butch from '../../assets/butch.jpg';
import ContainerImageWrapper from './common/container-image-wrapper';
import PageHeader from './common/page-header';
import PageContent from './common/page-content';

const AboutContainer = styled(Element)`
  padding: 60px 50px;
`;

const ButchImage = styled.img`
  border-radius: 16px;
  box-shadow: 5px 5px 20px #555555;
  height: 300px;
`;

function About(props) {
  return (
    <AboutContainer id="about" name="about" style={{ backgroundColor: props.backgroundColor }}>
      <Row>
        <Col sm={{ size: 8 }}>
          <Row>
            <Col>
              <PageHeader>About Butch</PageHeader>
            </Col>
          </Row>
          <Row>
            <Col>
              <PageContent>
                <p>
                  In 2001, I started my career at Toyota as a lot attendant and began my apprenticeship there. I later
                  finished my apprenticeship at Ford and obtained my Red Seal Interprovincial License. I was employed at
                  CN Railway for the last 10 years; maintaining the vehicles and completing the repairs on all CNs fleet
                  vehicles throughout Canada, as well as managing daily operations in my role as Shop Foreman. During
                  those years, I gained a vast amount of knowledge on Hirail vehicles and learning how to manage a fleet
                  of vehicles.
                </p>
                <p>
                  I started this company because I believe that my experience and skill with Hi-Rails is essential in
                  the railway industry. I enjoy my career and am confident that I can provide you the best service and
                  builds.
                </p>
              </PageContent>
            </Col>
          </Row>
        </Col>

        <ContainerImageWrapper sm={{ size: 4 }}>
          <ButchImage src={butch} alt="Butch" />
        </ContainerImageWrapper>
      </Row>
    </AboutContainer>
  );
}

export default About;
