import { Col, Row } from 'reactstrap';
import hours from '../../assets/hours.png';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import ContainerImageWrapper from './common/container-image-wrapper';
import PageHeader from './common/page-header';
import PageContent from './common/page-content';

const HoursContent = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

const HoursContainer = styled(Element)`
  padding: 60px 50px;
`;

const ContainerImage = styled.img`
  height: 300px;
`;

function Hours(props) {
  return (
    <HoursContainer id="hours" name="hours" style={{ backgroundColor: props.backgroundColor }}>
      <Row>
        <Col sm={{ size: 8 }}>
          <Row className="text-center">
            <Col>
              <PageHeader>Hours of Operation</PageHeader>
            </Col>
          </Row>
          <Row>
            <Col>
              <PageContent>
                <HoursContent>
                  <p className="textblock">
                    <div className="textblockHeader">T&B Fleet Services has the following availability:</div>
                    <ul>
                      <li>Monday: 07:00 - 18:00</li>
                      <li>Tuesday: 07:00 - 18:00</li>
                      <li>Wednesday: 07:00 - 18:00</li>
                      <li>Thursday: 07:00 - 18:00</li>
                      <li>Friday: 07:00 - 18:00</li>
                      <li>Saturday: Closed</li>
                      <li>Sunday: Closed</li>
                    </ul>
                    <div className="textblockFooter">
                      <i>Holidays may effect regular business hours</i>
                    </div>
                  </p>
                </HoursContent>
              </PageContent>
            </Col>
          </Row>
        </Col>
        <ContainerImageWrapper sm={{ size: 4 }}>
          <ContainerImage src={hours} alt="Hours" />
        </ContainerImageWrapper>
      </Row>
    </HoursContainer>
  );
}

export default Hours;
