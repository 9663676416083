import { Col, Row } from 'reactstrap';
import PageHeader from './common/page-header';
import rafna307RComplete from '../../assets/Rafna 307R Complete Install.jpg';
import rafnaR290Complete from '../../assets/Rafna R290 Complete Install.jpg';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

const ProjectImagesContainer = styled(Row)`
  margin-top: 10px;
`;

const Thumbnail = styled.div`
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
`;

const ThumbnailImg = styled.img`
  margin-bottom: 10px;
  border-radius: 16px;
  box-shadow: 5px 5px 20px #555555;
`;

const CompletedBuildsContainer = styled(Element)`
  padding: 60px 50px;
`;

function CompletedBuilds(props) {
  return (
    <CompletedBuildsContainer
      id="completedbuilds"
      name="completedbuilds"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <Row>
        <Col>
          <Row>
            <Col>
              <PageHeader style={{ textAlign: 'center' }}>Completed Builds</PageHeader>
            </Col>
          </Row>
          <ProjectImagesContainer className="text-center">
            <Col sm={{ size: 6 }}>
              <Thumbnail>
                <ThumbnailImg src={rafna307RComplete} />
                <p>Rafna 307R Complete Install</p>
              </Thumbnail>
            </Col>
            <Col sm={{ size: 6 }}>
              <Thumbnail>
                <ThumbnailImg src={rafnaR290Complete} />
                <p>Rafna R290 Complete Install</p>
              </Thumbnail>
            </Col>
          </ProjectImagesContainer>
        </Col>
      </Row>
    </CompletedBuildsContainer>
  );
}

export default CompletedBuilds;
