import React, { useState } from 'react';
import { Jumbotron, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { scroller } from 'react-scroll';
import About from './about';
import Hours from './hours';
import Services from './services';
import Contact from './contact';
import PastProjects from './past-projects';
import Rafna307R from './rafna-307r';
import CompletedBuilds from './completed-builds';
import ServiceLounge from './service-lounge';
import styled from 'styled-components';
import fleet from '../../assets/fleet.jpg';
import ContactUsButton from '../../components/contact-us-button';
import smallLogo from '../../assets/logo.jpg';
import RentalTrucks from './rental-trucks';

const Page = styled.div`
  width: 100%;
  height: 100%;
  background-color: #eee;
  font-size: 15px;
  font-family: 'Montserrat';

  .textblock {
    margin-left: auto;
    @media (max-width: 1200px) {
      margin-left: 0;
    }
    margin-right: auto;
    width: 250px;
  }

  .textblockHeader {
    width: 490px;
    @media (max-width: 892px) {
      width: 265px;
    }
  }

  .textblockFooter {
    width: 330px;
  }
`;

const SmallLogo = styled.img`
  height: 25px;
  margin-right: 5px;
`;

const Fleet = styled.div`
  width: 100%;
  max-width: 800px;
  height: auto;
  position: relative;
  z-index: 5;
  padding-bottom: 50px;
`;

const FleetImage = styled.img`
  width: 100%;
  height: auto;
`;

const Splash = styled(Jumbotron)`
  height: calc(100vh - 50px);
  background-color: #fff;
  padding: 0;
  margin-bottom: 0;
  font-family: Montserrat;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Banner = styled.div`
  width: 100%;
  @media (max-width: 950px) {
    width: 100%;
  }
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const JumbotronSecondaryText = styled.p`
  font-size: 20px;
`;

const Footer = styled.footer`
  padding: 15px;
`;

const MyNavBar = styled(Navbar)`
  font-family: Montserrat;
  background-color: #fff;
`;

const NavLinkScroll = styled(NavLink)`
  cursor: pointer;
`;

function LandingPage() {
  const [isOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggle = () => setIsDropdownOpen(!isDropdownOpen);

  const onClick = (element) => {
    scroller.scrollTo(element, {
      duration: 500,
      smooth: true,
    });
  };

  const navBarProps = { light: true, expand: 'md', sticky: 'top' };

  const sections = [
    Services,
    PastProjects,
    RentalTrucks,
    Rafna307R,
    CompletedBuilds,
    ServiceLounge,
    Hours,
    Contact,
    About,
  ];

  return (
    <div id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <MyNavBar {...navBarProps}>
        <NavbarBrand href="/">
          <SmallLogo src={smallLogo} alt="T&B" />
          T&B Fleet Services
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLinkScroll onClick={() => onClick('about')}>About Butch</NavLinkScroll>
            </NavItem>
            <NavItem>
              <NavLinkScroll onClick={() => onClick('services')}>Services</NavLinkScroll>
            </NavItem>
            <NavItem>
              <NavLinkScroll onClick={() => onClick('pastProjects')}>Recent Jobs</NavLinkScroll>
            </NavItem>
            <NavItem>
              <NavLinkScroll onClick={() => onClick('hours')}>Hours</NavLinkScroll>
            </NavItem>
            <NavItem>
              <NavLinkScroll onClick={() => onClick('contact')}>Contact</NavLinkScroll>
            </NavItem>
          </Nav>
        </Collapse>
      </MyNavBar>

      <Page className="animated fadeIn">
        <Splash>
          <Banner>
            <Fleet>
              <FleetImage src={fleet} />
            </Fleet>
            <JumbotronSecondaryText className="lead">
              T&B Fleet Services is a fleet maintenance and repair garage specializing in hi-rail installation.
            </JumbotronSecondaryText>
            <ContactUsButton onClick={() => onClick('contact')}>Contact Us</ContactUsButton>
          </Banner>
        </Splash>
        {sections.map((Section, i) => (
          <Section key={i} backgroundColor={i % 2 !== 0 ? '#fff' : '#f6f6f6'} />
        ))}
      </Page>

      <Footer className="container-fluid text-center">
        <a href="#myPage" title="To Top">
          Back To Top
        </a>
        <p>T&B Fleet Services</p>
      </Footer>
    </div>
  );
}

export default LandingPage;
