import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

import { Page404, Page500, Landing } from './pages';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" name="Landing Page" component={Landing} />
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
      </Switch>
    </Router>
  );
}

export default App;