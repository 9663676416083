import { Col, Row } from 'reactstrap';
import contact from '../../assets/contact.jpg';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import ContainerImageWrapper from './common/container-image-wrapper';
import PageHeader from './common/page-header';
import PageContent from './common/page-content';
import ContainerImage from './common/container-image';

const ContactContainer = styled(Element)`
  padding: 60px 50px;
`;

const ContactContent = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 750px;
  margin-top: 80px;
`;

function Contact(props) {
  return (
    <ContactContainer id="contact" name="contact" style={{ backgroundColor: props.backgroundColor }}>
      <Row>
        <ContainerImageWrapper sm={{ size: 4 }}>
          <ContainerImage src={contact} alt="Contact" />
        </ContainerImageWrapper>
        <Col sm={{ size: 8 }}>
          <Row>
            <Col>
              <PageHeader>Contact</PageHeader>
            </Col>
          </Row>
          <Row>
            <Col>
              <PageContent>
                <Row>
                  <Col sm={{ size: 6 }}>
                    <p>Contact us for general inquiries</p>
                    <p>
                      <span className="glyphicon glyphicon-map-marker" /> #2-877 Redonda St.
                      <br /> Sunnyside, MB <br />
                      R5R 0J4
                    </p>
                    <p>
                      <span className="glyphicon glyphicon-phone" /> (204) 222-3608
                    </p>
                    <p>
                      &#105;&#110;&#102;&#111;&#064;&#116;&#098;&#102;&#108;&#101;&#101;&#116;&#115;&#101;&#114;&#118;&#105;&#099;&#101;&#115;&#046;&#099;&#097;
                    </p>
                  </Col>
                </Row>
              </PageContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContactContainer>
  );
}

export default Contact;
