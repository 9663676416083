import { Col, Row } from 'reactstrap';
import services from '../../assets/services.png';
import vmac from '../../assets/vmac.png';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import PageHeader from './common/page-header';

const ServiceContent = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 750px;
  margin-top: 80px;
`;

const ServicesContainer = styled(Element)`
  padding: 20px 50px;
`;

const InnerServiceContent = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const ServicesHeader = styled(PageHeader)`
  @media (max-width: 767px) {
    text-align: center;
  }
  padding-bottom: 0;
  margin-bottom: 0;
`;

const ContainerImage = styled.img`
  height: 300px;
`;

const ContainerImageWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function Services(props) {
  return (
    <ServicesContainer id="services" name="services" style={{ backgroundColor: props.backgroundColor }}>
      <Row>
        <Col className="text-center">
          <ServicesHeader>Services</ServicesHeader>
        </Col>
      </Row>
      <Row>
        <ContainerImageWrapper md={{ size: 4 }}>
          <ContainerImage src={services} alt="Services" />
          <img src={vmac} style={{ marginTop: '24px' }} />
        </ContainerImageWrapper>
        <Col md={{ size: 8 }}>
          <ServiceContent>
            <div>T&B Fleet Services offers a variety of services:</div>
            <InnerServiceContent>
              <div>
                <ul>
                  <li>Light duty Hirail truck builds</li>
                  <li>Light customization & fabricating</li>
                  <li>Certified Hirail Inspections and Repairs</li>
                  <li>Hirail services & repairs</li>
                  <ul>
                    <li>Rafna 307a and 290 rail gear</li>
                    <li>DMF rail gear</li>
                    <li>Continental Railworks</li>
                  </ul>
                </ul>
              </div>
              <div style={{ display: 'inline' }}>
                <ul>
                  <li>Safety Inspections on:</li>
                  <ul>
                    <li>Vehicles up to 8900Kgs</li>
                    <li>Trailers up to 8900Kg</li>
                    <li>Bus up to 24 passenger</li>
                  </ul>
                  <li>Regular maintenance & repairs</li>
                  <li>Air Conditioning Services 1234YF / R134A</li>
                  <li>Authorized VMAC dealer</li>
                </ul>
              </div>
            </InnerServiceContent>
            <span>Available to pick up and drop off units that require services within the city of Winnipeg.</span>
          </ServiceContent>
        </Col>
      </Row>
    </ServicesContainer>
  );
}

export default Services;
